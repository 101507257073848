
@import "https://cdn.jsdelivr.net/npm/@mdi/font@7.4.47/css/materialdesignicons.min.css";

#page-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    min-width: 320px;
    min-height: 100vh;
    max-width: 1920px;
}

#sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    overflow-y: visible;
    background-color: #fff;
    transform: translateX(0) translateY(0) translateZ(0);
    box-shadow: -5px 0px 10px #3c3c3c;
}

#main-container {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    max-width: 100%;
}

.button-sidebar .btn-right,
.button-sidebar .btn-left {
    position: absolute;
    border: 0px solid transparent;
    padding: 5px;
    top: 6px;
    font-size: 1.5em;
}

.button-sidebar .btn-right {
    border-radius: 0px 4px 4px 0px;
    right: -40px;
    background-color: #f9e022;
    padding: 5px 10px;
    color: white;
}

.button-sidebar .btn-left {
    right: 0px;
    background-color: transparent;
    padding: 5px 20px;
    color: black;
}

.title {
    background-color: #394771;
    color: white;
}

@media (min-width:992px) {
    #sidebar {
        width: 200px;
    }

    #page-container.sidebar-o {
        padding-left: 200px;
    }

    .button-sidebar {
        display: none;
    }

    .title {
        text-align: left;
    }
}

@media (max-width:992px) {
    #sidebar {
        width: 100%;
        transform: translateX(-100%) translateY(0) translateZ(0);
    }

    #page-container .sidebar-o-xs {
        transition: transform .45s cubic-bezier(.2, .61, .42, .97);
    }

    #page-container .sidebar-open {
        transform: translateX(0) translateY(0) translateZ(0);
    }

    .title {
        text-align: right;
    }
}

/* Add */

.header-sidebar-banner {
    background-position-y: center;
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 28px;
    align-items: center;
    display: flex;
    flex-direction: row;
}

.header-sidebar-logo {
    max-width: 85px;
    margin: 8px 8px 8px 12px;
    border-radius: 8px;
}

.header-sidebar-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.sidebar-footer {
    height: 85px;
    background-size: 80% auto;
    background-repeat: no-repeat;
    background-position-x: center;
}

.head-div {
    background: #f5f5f5;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
}

.btn-green {
    background-color: #63be09;
    color: white;
}

.btn-green:hover {
    background-color: #619929;
    color: white;
}

.btn-green:active {
    background-color: #619929 !important;
    color: white !important;
    border: none;
}

.bg-gray {
    background-color: #adadad;
}

.btn-user, .btn-user:hover, .btn-user:active {
    color: gray !important;
    font-size: 1.3em;
    border: none !important;
}


@media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-start {
        width: 75% !important;
    }
}
